import React from 'react'
import { Anchor, Button, Box, Heading, Text, TextInput, Image } from 'grommet'
import colours from '../util/colours'

export default class OptionSelector extends React.Component {
  state = {
    selectedIndex: 1,
    initialIndex: 1,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset !== this.props.reset) {
      this.setInitialValues()
    }
  }

  getFoodLevel = risk => {
    if (risk < 0.36) {
      return 'low'
    }
    if (risk < 0.64) {
      return 'med'
    }
    return 'high'
  }

  setInitialValues = () => {
    const { item } = this.props
    const setItems = defaultId => {
      const selectedIndex = item.options.findIndex(opt => opt.id === defaultId)
      this.setState({ selectedIndex, initialIndex: selectedIndex })
    }
    if (item.logic) {
      setItems(this.getDefaultByLogic(item))
    } else {
      setItems(this.getDefault(item))
    }
  }

  componentDidMount = () => {
    this.setInitialValues()
  }

  getDefaultByLogic = item => {
    const { ageGender } = this.props
    const activeLogic = item.logic.find(logic => {
      if (logic.risk) {
        if (this.pass(logic, ageGender)) {
          const riskData = this.props.allRisks[logic.risk.id]
          const riskWord = this.getFoodLevel(riskData.risk)
          if (logic.risk.level.includes(riskWord)) {
            return logic
          }
        }
      } else if (this.pass(logic, ageGender)) {
        return logic
      }
      return false
    })
    if (activeLogic) {
      return activeLogic.optId
    }
    return this.getDefault(item)
  }

  pass = (logic, ageGender) => {
    if (
      (logic.ageMin <= ageGender.age
        && logic.ageMax >= ageGender.age
        && logic.gender === ageGender.gender)
      || (logic.ageMin <= ageGender.age
        && logic.ageMax >= ageGender.age
        && !logic.gender)
      || (logic.gender === ageGender.gender && !logic.ageMin)
    ) {
      return true
    }
    return false
  }

  getDefault = item => {
    const selected = item.options.find(opt => opt.target === this.props.risk)
    return selected.id
  }

  geneAdjusted = id => Object.keys(this.props.allRisks).find(
      risk => this.props.allRisks[risk].id === id,
    )

  render() {
    return (
      <Box
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 24,
          marginBottom: 8,
        }}
      >
        <Box
          style={{
            flexDirection: 'row',
            flex: 0.65,
          }}
        >
          <Text
            style={{
              flexWrap: 'wrap',
            }}
          >
            {this.props.item.title}
          </Text>
          {this.geneAdjusted(this.props.item.id) ? (
            <Box
              style={{
                borderRadius: 23,
                backgroundColor: colours.PRIMARY,
                marginHorizontal: 4,
              }}
            >
              {this.state.initialIndex === this.state.selectedIndex ? (
                <Text
                  style={{
                    color: colours.WHITE,
                    paddingHorizontal: 4,
                    alignSelf: 'center',
                  }}
                >
                  Gene adjusted
                </Text>
              ) : null}
            </Box>
          ) : null}
        </Box>
        <Box
          style={{
            flexDirection: 'row',
            width: 150,
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 0.35
          }}
        >

            <Text
              style={{ alignSelf: 'center', fontWeight: '500', fontSize: 16 }}
            >
              {this.props.item.options[this.state.selectedIndex].title}
            </Text>
            <Text style={{ alignSelf: 'center', fontSize: 12 }}>
              {this.props.item.unit}
            </Text>

        </Box>
      </Box>
    )
  }
}