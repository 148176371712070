import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Box,
  Heading,
  Text,
  TextInput,
  Accordion,
  AccordionPanel
} from 'grommet'
import AppLayout from '../components/app-layout'
import OptionSelector from '../components/option-selector'

import {
  getAdminUserRisks,
  getPlatformProduct,
  getUserCheck
} from '../actions/admin'

const getTitleFromCheck = prop => {
  switch (prop) {
    case 'mobileConnect': return 'DNA data in mobile bucket'
    case 'webUpload': return 'DNA data in web bucket '
    case 'latestFileConnected': return 'Latest dna data connected'
    case 'snpsExtracted': return 'Snps extracted from DNA file'
    case 'storyGenerated': return 'Stories generated'
    case 'permissions': return 'Available User Permissions'
    case 'userOnboarded': return 'User has onboarded'
  }
}

const mapStateToProps = state => ({
  ...state.user,
  isAdmin: state.admin.isAdmin,
  userRisks: state.admin.risks,
  product: state.admin.product,
  userCheck: state.admin.userCheck,
})

const mapDispatchToProps = dispatch => ({
  onGetUserRisks: uid => dispatch(getAdminUserRisks(uid)),
  onGetPlatformProduct: () => dispatch(getPlatformProduct()),
  onGetUserCheck: uid => dispatch(getUserCheck(uid)),
})

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clientId: ''
    }
  }

  getUserFormula = () => {
    this.props.onGetUserRisks(this.state.clientId)
    this.props.onGetPlatformProduct()
    this.setState({ random: Math.random() })
  }

  getFoodLevel = risk => {
    if (risk < 0.36) {
      return 'low'
    }
    if (risk < 0.64) {
      return 'med'
    }
    return 'high'
  }

  getRiskWord = id => {
    const riskKey = Object.keys(this.props.userRisks.data).find(
      risk => this.props.userRisks.data[risk].id === id
    )
    if (!riskKey) {
      return 'low'
    }
    const riskNum = this.props.userRisks.data[riskKey].risk
    const riskWord = this.getFoodLevel(riskNum)
    return riskWord
  }

  render() {
    const {
      isAdmin,
      product,
      userRisks,
      userCheck,
      onGetUserCheck,
    } = this.props
    if (isAdmin) {
      return (
        <AppLayout>
          <Box
            pad={{ horizontal: 'large', vertical: 'large' }}
            margin={{ horizontal: 'small' }}
          >
            <Heading level={2} color="accent-2">
              Admin
            </Heading>

            <Accordion>
              <AccordionPanel label="Vitamin Lab Formula Tool">
                <Box pad="medium" background="light-2">
                  <Box style={{ flexDirection: 'row' }}>
                    <TextInput
                      placeholder="user UID"
                      value={this.state.clientId}
                      onChange={event =>
                        this.setState({ clientId: event.target.value })
                      }
                    />
                    <Box style={{flexDirection:'row'}}>
                      <TextInput
                        placeholder="age"
                        value={this.state.age}
                        onChange={event =>
                          this.setState({ age: event.target.value })
                        }
                      />
                      <TextInput
                        placeholder="gender"
                        value={this.state.gender}
                        onChange={event =>
                          this.setState({ gender: event.target.value })
                        }
                      />
                    </Box>
                    <Button
                      label="Submit"
                      color="accent-1"
                      primary
                      style={{ marginLeft: 16 }}
                      onClick={() => this.getUserFormula()}
                    />
                  </Box>
                  {product && userRisks.data ? (
                    <Box>
                      {product.productOptions.map(option => (
                        <Box key={option.id}>
                          <Text>{option.title.toUpperCase()}</Text>
                          {option.options.map(opt => (
                            <OptionSelector
                              reset={this.state.random}
                              key={opt.id}
                              item={opt}
                              risk={this.getRiskWord(opt.id)}
                              allRisks={userRisks.data}
                              ageGender={{
                                age: this.state.age,
                                gender: this.state.gender
                              }}
                            />
                          ))}
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                </Box>
              </AccordionPanel>
              <AccordionPanel label="User Connection Check Tool">
                <Box pad="medium" background="light-2">
                  <Box style={{ flexDirection: 'row' }} margin={{ bottom: 'small' }}>
                    <TextInput
                      placeholder="user UID"
                      value={this.state.clientId}
                      onChange={event =>
                        this.setState({ clientId: event.target.value })
                      }
                    />
                    <Button
                      label="Check Status"
                      color="accent-1"
                      primary
                      style={{ marginLeft: 16 }}
                      onClick={() => onGetUserCheck(this.state.clientId)}
                    />
                  </Box>
                  {
                    (!!userCheck && userCheck.error) && (
                      <Box background='status-warning'>
                        <Text>
                          {userCheck.error}
                        </Text>
                      </Box>
                    )
                  }
                  {
                    (!!userCheck && !userCheck.error) && (
                      Object.keys(userCheck).map(key => (
                        <Box key={key} margin={{ bottom: 'small' }}>
                          <Text weight="bold">
                            {getTitleFromCheck(key)}
                          </Text>
                          {
                            typeof userCheck[key] === 'string' && (
                              <Text>
                                {userCheck[key]}
                              </Text>
                            )
                          }
                          {
                            typeof userCheck[key] === 'boolean' && (
                              <Text>
                                {userCheck[key] ? 'yes' : 'n/a'}
                              </Text>
                            )
                          }
                          {
                            Array.isArray(userCheck[key]) && (
                              userCheck[key].map(innerKey => (
                                <Box>
                                  <Text>
                                    {innerKey}
                                  </Text>
                                </Box>
                              ))
                            )
                          }
                        </Box>
                      ))
                    )
                  }
                </Box>
              </AccordionPanel>
            </Accordion>
          </Box>
        </AppLayout>
      )
    }
    return (
      <AppLayout>
        <Box
          pad={{ horizontal: 'large', vertical: 'large' }}
          margin={{ horizontal: 'small' }}
        >
          {
            isAdmin === null ? (
              <Heading level={2} color="accent-2">
                Loading...
              </Heading>
            ) : (
              <Heading level={2} color="accent-2">
                404 Error
              </Heading>
            )
          }
        </Box>
      </AppLayout>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)
